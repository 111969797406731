/**
 * LM-LIFF-11 資格判斷
 * https://lmliff.cht.com.tw/unqualified
 */
import { useCallback, useEffect, useMemo } from 'react';

import API_RESPONSE_CODE from '@/apis/axios/responseCode';
import { ApiResponseData } from '@/apis/axios/types';
import imgActivityEnd from '@/assets/images/img-message-primary-410.svg';
import imgError from '@/assets/images/img-message-primary-500.svg';
import imgSystemBusy from '@/assets/images/img-message-primary-5031.svg';
import imgSystemMaintain from '@/assets/images/img-message-primary-5032.svg';
import imgUnqualified from '@/assets/images/img-unqualified.svg';
import StandardButton from '@/components/StandardButton';
import useLoading from '@/hooks/useLoading';
import ModuleFlowResult from '@/modules/ModuleFlowResult';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

import { REDIRECT_TO_URL } from '../../constants/urls';

const StyledImage = styled(SVG)`
  margin-top: 82px;
  margin-bottom: 52px;
`;

const MessagePage = ({ location }) => {
  const {
    /** postEvent RESPONSE的code */
    code,
    /** postEvent RESPONSE的data下的欄位 */
    title,
    message,
    target_url: redirectUrl,
  } = useMemo(
    () =>
      (typeof window !== 'undefined' && location.state) ?? {
        code: null,
        title: '發生異常',
        message: '請稍後再試',
        target_url: REDIRECT_TO_URL.LINE_OA_FOLLOW,
      },
    [location]
  );
  const { isLoading, showLoading, hideLoading } = useLoading();

  useEffect(() => {
    if (isLoading && !!title) hideLoading();
  }, [title, isLoading, hideLoading, showLoading]);

  /**
   * onConfirmToGo 確認按鈕
   * 轉導頁面
   */
  const onConfirmToGo = useCallback(() => {
    window.location.replace(redirectUrl);
  }, [redirectUrl]);

  const renderButtons = () => (
    <>
      <StandardButton type="primary" onClick={onConfirmToGo}>
        返回官方帳號
      </StandardButton>
    </>
  );
  return (
    <ModuleFlowResult
      title={title}
      message={message}
      renderImage={() => {
        /** Response code指定圖片 */
        if (code === API_RESPONSE_CODE.MESSAGE_TYPE_ACTIVITY_END) {
          return <StyledImage src={imgActivityEnd} />;
        }
        if (code === API_RESPONSE_CODE.MESSAGE_TYPE_ERROR) {
          return <StyledImage src={imgError} />;
        }
        if (code === API_RESPONSE_CODE.MESSAGE_TYPE_SYSTEM_BUSY) {
          return <StyledImage src={imgSystemBusy} />;
        }
        if (code === API_RESPONSE_CODE.MESSAGE_TYPE_SYSTEM_MAINTAIN) {
          return <StyledImage src={imgSystemMaintain} />;
        }
        /** 資格不符未定義 */
        if (code === 100333) {
          return <StyledImage src={imgUnqualified} />;
        }
        return <StyledImage src={imgSystemBusy} />;
      }}
      renderButtons={renderButtons}
    />
  );
};
MessagePage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.oneOfType([
      PropTypes.instanceOf(ApiResponseData),
      PropTypes.object,
    ]),
  }),
};
export default MessagePage;
